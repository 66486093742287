import axios from 'axios';
import to from '~/utils/to';

const FIVE_MINUTES = 1000 * 60 * 5;

class AuthService {
  /**
   * @protected
   * @var {number|null} interval
   */
  interval = null;

  /**
   * @protected
   * @type {null|{csrf:string,status:boolean}}
   */
  result = null;

  constructor(baseURL) {
    this.axiosInstance = axios.create({
      baseURL,
      withCredentials: true,
    });

    this.setupRefresher();
  }

  setupRefresher() {
    if (this.interval) {
      clearInterval(this.interval);
      this.interval = null;
    }

    this.interval = setInterval(async () => {
      this.result = await this.refresh();
    }, FIVE_MINUTES);
  }

  async refreshResult() {
    if (!this.result) {
      this.result = await this.refresh();
      this.setupRefresher();
    }

    return this.result;
  }

  async login(credentials) {
    const { data } = await this.axiosInstance.post('reservation-number/', credentials);
    return data;
  }

  async loginWithCid(credentials) {
    const { data } = await this.axiosInstance.post('reservation-number-cid/', credentials);
    return data;
  }

  async refresh() {
    const [err, response] = await to(this.axiosInstance.get('refresh/'));

    if (err) {
      return {
        status: false,
        csrf: null,
      };
    }

    return {
      status: true,
      csrf: response.data.csrf,
    };
  }

  async logout() {
    const { data } = await this.axiosInstance.get('logout/');
    return data;
  }
}

const authInstances = {};

/**
 * @param {string} name
 * @param {string|undefined} baseUrl if not provided must be already initialized
 * @returns {AuthService}
 */
function getAuthInstance(name, baseUrl = undefined) {
  if (!(name in authInstances)) {
    if (typeof baseUrl === 'undefined') {
      throw new TypeError(`BaseUrl is required to initialize the auth instance for "${name}".`);
    }
    authInstances[name] = new AuthService(baseUrl);
  }

  return authInstances[name];
}

export default getAuthInstance;
